// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-beer-post-template-js": () => import("./../src/templates/beerPostTemplate.js" /* webpackChunkName: "component---src-templates-beer-post-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-coffee-post-template-js": () => import("./../src/templates/coffeePostTemplate.js" /* webpackChunkName: "component---src-templates-coffee-post-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beer-js": () => import("./../src/pages/beer.js" /* webpackChunkName: "component---src-pages-beer-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-coffee-js": () => import("./../src/pages/coffee.js" /* webpackChunkName: "component---src-pages-coffee-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-content-beer-beer-mdx": () => import("./../src/pages/content/beer/beer.mdx" /* webpackChunkName: "component---src-pages-content-beer-beer-mdx" */),
  "component---src-pages-content-blog-first-post-mdx": () => import("./../src/pages/content/blog/first-post.mdx" /* webpackChunkName: "component---src-pages-content-blog-first-post-mdx" */),
  "component---src-pages-content-coffee-my-routine-mdx": () => import("./../src/pages/content/coffee/my-routine.mdx" /* webpackChunkName: "component---src-pages-content-coffee-my-routine-mdx" */),
  "component---src-pages-content-coffee-single-origin-mdx": () => import("./../src/pages/content/coffee/single-origin.mdx" /* webpackChunkName: "component---src-pages-content-coffee-single-origin-mdx" */),
  "component---src-pages-resume-mdx": () => import("./../src/pages/resume.mdx" /* webpackChunkName: "component---src-pages-resume-mdx" */)
}

